.Profile {
    /* width          : 86%; */
     width          : 100%;
     overflow-x: hidden;
    height         : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
}

.ProfileHeader {
    position       : fixed;
    top            : 0;
    width          : 100%;
   
    height         : 198px;
    background     : #fff;
    z-index        : 1;
    border-bottom  : 1px solid #e0e0e0;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    max-width: 706px;
}
.ProfileHeaderWithoutPhone{
    position       : fixed;
    top            : 0;
    width          : 100%;
    height         : 137px;
    /* height         : 198px; */

    background     : #fff;
    z-index        : 1;
    border-bottom  : 1px solid #e0e0e0;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    max-width: 706px;  
    padding-top: 1rem;
    padding-bottom: 1.2rem;
}
.ProfileHeaderImg {
    width        : 44px;
    height       : 44px;
    border-radius: 100%;
    margin-top: 8px;
}

.ProfileHeaderEmail {
    color      : #212121;
    font-size  : 14px;
    font-weight: 400;
    font-family: Product Sans;
    margin-top : 5px;
}

.ProfileHeaderEmailwithoutPhone {
    color      : #212121;
    font-size  : 14px;
    font-weight: 400;
    font-family: Product Sans;
    margin-top : 12px;
}
.ProfileHeaderPhone {
    color          : #212121;
    font-size      : 14px;
    font-weight    : 400;
    font-family    : Product Sans;
    margin-top     : 8px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.ProfileHeaderPhoneEdit {}

.EditOutlinedIcon1 {
    width       : 18px !important;
    height      : 18px !important;
    margin-right: 8px;
}

.ProfileHeaderPhoneEditButton {
    border         : 1px solid #e0e0e0;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #1976D2;

    font-weight       : 500;
    font-family       : Product Sans;
    border-radius     : 30px;
    background        : #fff;
    margin-top        : 8px;
    /* padding        : 12px 16px 11px 8px; */
    height            : 40px;
    width             : 133px;
    font-size         : 14px !important;
}

.ProfileContent {
    padding-top    : 198px;
    padding-bottom : 8px;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-top     : 8px;
    border-bottom  : 1px solid #e0e0e0;
    max-width      : 706px;

}
.ProfileContentWithoutPhone{
    padding-top    : 170px;
    padding-bottom : 8px;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-top     : 8px;
    border-bottom  : 1px solid #e0e0e0;
    max-width      : 706px;  
}
.ProfileTextIcon {
    /* width          : 91%; */
    width          : 100%;
    padding: 0 4%;
    margin         : auto;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    color          : #212121;
    font-size      : 14px !important;
    font-weight    : 400;
    font-family    : Product Sans;
    height         : 56px;
    cursor         : pointer;
}

.ProfileIconColor {
    color       : #616161;
    width       : 24px;
    height      : 24px;
    margin-right: 24px;
}

.LogoutProfile {
    padding-top       : 8px;
    padding-bottom    : 8px;
    width             : 100%;
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    align-items       : center;
    max-width         : 706px;
    /* margin-top     : 8px; */
    /* border-bottom  : 1px solid #e0e0e0; */
}
@media (min-width: 980px) {
    .LogoutProfile:hover{
     cursor: pointer;
     /* background: #F5F5F5; */
    }
   .ProfileTextIcon:hover{
    cursor: pointer;
    background: #F5F5F5;
   }
   .ProfileHeaderPhoneEditButton:hover{
    background: #E3F2FD;
   }
   }

   .NameAccountDiv{
    color          : #212121;
    font-size      : 14px !important;
    font-weight    : 500;
    font-family    : Product Sans;
   
   }
   .AccountText{
 margin-bottom: 16px;
   }