.AddAccountContainer {
    width             : 90%;
    max-width         : 768px;
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    align-items       : center;
    margin            : auto;
    margin-top        : 80px;
    overflow          : scroll;
    position          : relative;
    -ms-overflow-style: none;
    scrollbar-width   : none
}

.AddAccountTitle {
    font-family: Product Sans;
    font-weight: 500;
    font-size  : 20px;
    color      : #212121;
    margin-top : 24px
}

.AddAccountDes {
    margin-top     : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    width: 100%;
}

.AddAccountDesTextItem2 {
    line-height: 20px
}

.AddAccountDesSteps {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center
}

.AddAccountDesText {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : start;
    font-size      : 14px;
    font-family    : Product Sans;
    font-weight    : 400;
    margin-left    : 10px
}

.AddAccountDesStepsItem {
    background     : #E0E0E0;
    width          : 16px;
    height         : 16px;
    border-radius  : 30px;
    font-size      : 12px;
    font-family    : Product Sans;
    font-weight    : 400;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.AddAccountDesStepsItemLine {
    background: #E0E0E0;
    width     : 2px;
    height    : 24px
}
.AddAccountDesTextItem1Ul{
    padding-inline-start: 18px !important;

}
.AddAccountDesTextItem1 {
    margin-bottom: 8px;
    font-size      : 16px;
    font-family    : Product Sans;
    font-weight    : 500;
    /* padding: 0 13px; */
}

.AddAccountDesText {
    height: 56px
}

.AddAccountButtonContainer {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.AddAccountButton {
    width          : 66%;
    background     : #1976d2;
    border-radius  : 30px;
    color          : #fff;
    font-size      : 16px;
    font-family    : Product Sans;
    font-weight    : 500;
    height         : 48px;
    outline        : none;
    border         : none;
    margin         : auto;
    margin-top     : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

@media (min-width:768px) {
    .AddAccountWarning {
        font-size  : 14px;
        font-family: Product Sans;
        font-weight: 400;
        width      : 75%;
        margin     : auto;
        margin-top : 48px;
        line-height: 25px
    }

    .AddAccountButton:hover {
        background: #1565C0
    }
}

.AddAccountButtonImg {
    margin-right: 8px
}

@media (max-width:768px) {
    .AddAccountWarning {
        font-size  : 14px;
        font-family: Product Sans;
        font-weight: 400;
        width      : 90%;
        margin     : auto;
        margin-top : 48px;
        line-height: 25px
    }
}