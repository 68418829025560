.Game {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : start;
    height         : 100%;
    margin         : auto;
    overflow       : scroll;
    margin-bottom  : 4rem;
    padding-bottom : 1rem
}

.TestforStyle {
    position: absolute;
    top     : 70px
}

.GameHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : 0 auto;
    animation      : fadeIn .5s ease forwards;
    background     : #EFF7FE;
    position: fixed;
    z-index: 10;
}

.GameHeaderInner {
    width          : 90%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : 0 auto;
    animation      : fadeIn .5s ease forwards;
    font-weight    : 700;
    font-family    : Product Sans !important;
    font-size      : 24px
}

@media (min-width:768px) {
    .GameHeaderProgress {
        width          : 15%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center
    }
}

@media (max-width:768px) {
    .GameHeaderProgress {
        width          : 35%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items    : center
    }
}

.HeaderProgressBarImgContainer {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.HeaderProgressBarImg {
    z-index: 1
}

.HeaderProgressBarAbsoluteNumber {
    position                 : absolute;
    color                    : #FFF;
    font-weight              : 700;
    font-family              : Product Sans !important;
    font-size                : 14px;
    z-index                  : 2;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: #000
}

.GameContent {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    max-width      : 768px;
    margin         : 0 auto;
    margin-top: 3rem;
}

.GameChips {
    width          : 100%;
    margin         : auto;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 24px
}

.GameChipsItemLeft {
    display                   : flex;
    flex-direction            : row;
    justify-content           : center;
    align-items               : center;
    font-size                 : 14px;
    font-weight               : 400;
    border                    : 1px solid #757575;
    border-top-left-radius    : 100px;
    border-bottom-left-radius : 100px;
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;
    width                     : 160px;
    background                : #fff;
    height                    : 40px;
    cursor                    : pointer
}

.GameChipsItemLeft:hover {
    background: #f5f5f5
}

.GameChipsItemRight:hover {
    background: #f5f5f5
}

.GameChipsItemRight {
    display                   : flex;
    flex-direction            : row;
    justify-content           : center;
    align-items               : center;
    font-size                 : 14px;
    font-weight               : 400;
    border                    : 1px solid #757575;
    border-left               : 0;
    border-top-left-radius    : 0;
    border-bottom-left-radius : 0;
    border-top-right-radius   : 100px;
    border-bottom-right-radius: 100px;
    width                     : 160px;
    height                    : 40px;
    cursor                    : pointer;
    background                : #fff
}

.noSelect {
    -webkit-tap-highlight-color: #fff0;
    -webkit-touch-callout      : none;
    -webkit-user-select        : none;
    -khtml-user-select         : none;
    -moz-user-select           : none;
    -ms-user-select            : none;
    user-select                : none
}

.noSelect:focus {
    outline: none !important
}

.GameChipsDone {
    width       : 20px !important;
    height      : 20px !important;
    margin-right: 8px
}

.GameChipsDoneHidden {
    width       : 20px !important;
    height      : 20px !important;
    margin-right: 8px;
    opacity     : 0
}

.ActiveChips {
    background: #E3F2FD;
    z-index   : 1
}

.ActiveChips:hover {
    background: #E3F2FD
}

.SelectComponent {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start;
    margin-top     : 24px
}

.GameFooter {
    width     : 100%;
    position  : fixed;
    bottom    : 0;
    background: #EFF7FE
}

.InTooltip {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start;
    color          : #212121
}

.InTooltip1 {
    font-weight: 400;
    font-family: Product Sans !important;
    font-size  : 10px
}

.InTooltip12 {
    font-weight: 700;
    font-family: Product Sans !important;
    font-size  : 14px;
    margin-top : 6px
}