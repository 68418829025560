.Header {
    position  : fixed;
    top       : 0;
    width     : 100%;
    height    : 56px;
    background: #EFF7FE;
    z-index   : 1
}

.HeaderContainer {
    width          : 90%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto
}

.HeaderCoin {
    font-weight   : 700;
    font-size     : 14px;
    font-family   : Product Sans;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    background: #FEF7E6;
    /* background: blue; */
    border-radius: 35px;
    padding: .1rem;
    cursor: pointer;
}

.HeaderCoinImg {
    margin-left: 4px
}
.AddCoinIcon{
    width: 24px !important;
    height: 24px !important;
    color: #1565C0;
    margin-right: 8px;
}